import React, { useState, useEffect } from 'react';

import { isEqual } from 'lodash';
import { NextPage } from 'next';
import { EqualHeight } from 'react-equal-height/clean';

import {
  Button,
  ContentTitle,
  PartnerCard,
  SearchInput,
  Select,
  Spinner,
} from '@/components/atoms';
import { FilterField, NoItems } from '@/components/molecules';
import { useGlobalState } from '@/contexts/GlobalStateContext';
import useCountries from '@/hooks/queries/constants/useCountries';
import usePartnersInfinte from '@/hooks/queries/partners/usePartnersInfinte';
import useEffectOnlyOnce from '@/hooks/useEffectOnlyOnce';
import { Meta } from '@/layouts/Meta';
import { PageLayout } from '@/layouts/PageLayout';
import { SelectOptionType } from '@/types/CommonTypes';
import { PartnerItemListType, PartnerListFilters } from '@/types/PartnerTypes';
import { partnersStatusesOptions } from '@/utils/constants';

import PartnersManagementIcon from '../../../svgs/icons/partners-management.svg';

type KeysPartnerListFilters = keyof PartnerListFilters;

type PartnersListType = {
  partners: PartnerItemListType[];
};

const PartnersList: React.FC<PartnersListType> = ({ partners }) => {
  return (
    <div className="flex flex-wrap -mx-2 partner-card-list">
      <EqualHeight updateOnChange={partners}>
        {partners &&
          partners.map((partner: PartnerItemListType) => (
            <div
              key={partner.backend_api_identifier}
              className="w-1/3 px-2 my-2 2xl:w-1/4"
            >
              <PartnerCard
                partner={partner}
                url={`/partners-management/partners/${partner.backend_api_identifier}/overview`}
              />
            </div>
          ))}
      </EqualHeight>
    </div>
  );
};

export const Partners: NextPage = () => {
  const { globalState, setGlobalState } = useGlobalState();
  const initializeFilters = {
    country: undefined,
    search: undefined,
    status: undefined,
  };
  const [filters, setFilters] = useState<PartnerListFilters>(initializeFilters);
  const [disabledFilters, setDisabledFilters] = useState(false);
  const [countries, setCountries] = useState<SelectOptionType[]>([]);
  const [partnerList, setPartnerList] = useState<PartnerItemListType[]>([]);

  const { data: countriesData } = useCountries();
  const { data, isPending, hasNextPage, fetchNextPage } =
    usePartnersInfinte(filters);

  const handleFilterChange = (
    prop: KeysPartnerListFilters,
    value: any,
  ): void => {
    const copyFilters = { ...filters };
    copyFilters[prop] = value?.value;
    setFilters(copyFilters);
  };

  useEffect(() => {
    if (data?.pages && !isPending) {
      const partnersListLoad: PartnerItemListType[] = [];
      data.pages.forEach((page) => {
        page.data.results.forEach((partner: PartnerItemListType) => {
          partnersListLoad.push(partner);
        });
      });
      setPartnerList(partnersListLoad);
    }
  }, [hasNextPage, isPending, data?.pages]);

  useEffect(() => {
    let fetching = false;
    const onScroll = async (event: Event) => {
      const target = event.target as HTMLElement;
      // @ts-ignore
      const { scrollHeight, scrollTop, clientHeight } = target.scrollingElement;

      if (!fetching && scrollHeight - scrollTop <= clientHeight * 2) {
        fetching = true;
        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (countriesData && countriesData.data) {
      const newCountries: SelectOptionType[] = [];
      Object.entries(countriesData.data).forEach((key) =>
        newCountries.push({
          label: key[1],
          value: key[0],
        }),
      );
      setCountries(newCountries);
    }
  }, [countriesData]);

  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      routerTracker: [],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnlyOnce(
    () => {
      setDisabledFilters(true);
    },
    [data?.pages],
    () =>
      !disabledFilters &&
      data?.pages[0]?.data.count === 0 &&
      isEqual(filters, initializeFilters),
  );

  return (
    <PageLayout
      breadcrumbs={['Partners Management', 'Partners']}
      icon={<PartnersManagementIcon className="w-8" />}
      meta={<Meta title="Partners" />}
      title="Partners"
    >
      <div className="flex items-center justify-between mb-2">
        <ContentTitle>UPT Partners</ContentTitle>
        {globalState.currencyOwner.role !== 'basic' && (
          <Button
            as="link"
            color="blue"
            href="/partners-management/partners/add-new-partner"
            variant="solid"
          >
            Add Partner
          </Button>
        )}
      </div>
      <div className="flex items-end justify-between mb-6">
        <div className="flex">
          <FilterField title="Status">
            <Select
              isClearable
              instanceId="status-select"
              isDisabled={disabledFilters}
              options={partnersStatusesOptions}
              value={partnersStatusesOptions.filter(
                (obj) => obj.value === filters.status,
              )}
              onChange={(value) => {
                handleFilterChange('status', value);
              }}
            />
          </FilterField>
          <FilterField title="Country" width={180}>
            <Select
              isClearable
              isSearchable
              instanceId="country-select"
              isDisabled={disabledFilters}
              options={countries}
              value={countries.find(
                (item: SelectOptionType) => item.value === filters.country,
              )}
              onChange={(value) => {
                handleFilterChange('country', value);
              }}
            />
          </FilterField>
        </div>
        <div>
          <SearchInput
            disabled={disabledFilters}
            id="search"
            placeholder="Search by Name"
            setFilters={setFilters}
            value={filters.search}
            onChange={(e) => handleFilterChange('search', e.target)}
          />
        </div>
      </div>
      <div>
        {data?.pages[0]?.data &&
          data?.pages[0]?.data.count === 0 &&
          (isEqual(filters, initializeFilters) ? (
            <NoItems
              btnText={
                globalState.currencyOwner.role !== 'basic'
                  ? 'Add Partner'
                  : undefined
              }
              btnUrl={
                globalState.currencyOwner.role !== 'basic'
                  ? '/partners-management/partners/add-new-partner'
                  : undefined
              }
              infoText="No partners have been added yet, add the first partner now."
            />
          ) : (
            <NoItems infoText="No partners found." />
          ))}
        {data?.pages[0]?.data && data?.pages[0]?.data.count > 0 && (
          <PartnersList partners={partnerList} />
        )}
        {isPending && (
          <div className="flex justify-center">
            <Spinner color="#2C3C54" />
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default Partners;
