import { axiosFrontend } from '@/utils/axios';

export const fetchCountries = async () => {
  return axiosFrontend.get<{ [key: string]: string }>(
    '/api/constants/countries',
  );
};

export const fetchFiatCurrencies = async () => {
  return axiosFrontend.get<{ [key: string]: string }>(
    '/api/constants/fiat-currencies',
  );
};

export const fetchInternationalCallingCodes = async () => {
  return axiosFrontend.get<{ [key: string]: string }>(
    '/api/constants/international-calling-codes',
  );
};
