import React, { useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';

import { Breadcrumbs, HeaderTitle, UserImage } from '@/components/atoms';
import { Content, Header, Main, Sidebar } from '@/components/molecules';
import { useGlobalState } from '@/contexts/GlobalStateContext';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import useToggle from '@/hooks/useToggle';
import { getDomainWithoutSubdomain, getJWTKeyName } from '@/utils/helpers';

import ChevronIcon from '../svgs/icons/chevron.svg';

export interface IPageLayoutProps {
  breadcrumbs: string[];
  children: React.ReactNode;
  icon: React.ReactNode;
  meta?: React.ReactNode;
  title: string;
}

const PageLayout: React.FC<IPageLayoutProps> = ({
  breadcrumbs,
  children,
  icon,
  meta = null,
  title,
}: IPageLayoutProps) => {
  const ref = useRef(null);
  const { value, setValue, toggle } = useToggle(false);
  const { globalState } = useGlobalState();
  const queryClient = useQueryClient();
  const [, , removeCookie] = useCookies([getJWTKeyName()]);

  const logout = () => {
    removeCookie(getJWTKeyName(), {
      domain: getDomainWithoutSubdomain(window.location.host),
      path: '/',
      secure: true,
      sameSite: 'none',
    });
    queryClient.clear();
    window.location.href = `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/#/auth/login`;
  };

  useOnClickOutside(ref, () => setValue(false));

  return (
    <div className="flex min-h-screen">
      {meta}
      <Sidebar />
      <Main>
        <Header>
          <div className="flex">
            <div className="pt-0.5">{icon}</div>
            <div className="pl-4">
              <HeaderTitle>{title}</HeaderTitle>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
            </div>
          </div>
          <div ref={ref} className="relative">
            <div className="flex items-center cursor-pointer" onClick={toggle}>
              <div className="flex flex-col text-right">
                <p className="text-base text-blue-800">
                  {globalState.currencyOwner?.first_name}{' '}
                  {globalState.currencyOwner?.last_name}
                </p>
                <span className="text-sm italic text-gray-500">
                  {globalState.currencyOwner?.partner_company_name}
                </span>
              </div>
              <div>
                <UserImage user={globalState.currencyOwner} />
              </div>
              <ChevronIcon className="w-4 h-4 text-blue-800" />
            </div>
            {value && (
              <div className="absolute right-0 w-[275px] top-[58px] text-white text-base z-50">
                <div
                  className="block px-6 py-4 bg-blue-900 border-b border-blue-800 cursor-pointer hover:bg-blue-800"
                  onClick={logout}
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        </Header>
        <Content>{children}</Content>
      </Main>
    </div>
  );
};

export { PageLayout };
