import { useQuery } from '@tanstack/react-query';

import { fetchCountries } from '@/services/constants';

export default function useCountries() {
  return useQuery({
    queryKey: ['countries'],
    queryFn: () => {
      return fetchCountries();
    },
    staleTime: 24 * 60 * 60,
    gcTime: Infinity,
  });
}
