import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchPartners } from '@/services/partners';
import { PartnerListFilters } from '@/types/PartnerTypes';
import { Config } from '@/utils/Config';

export default function usePartnersInfinte(filters: PartnerListFilters) {
  return useInfiniteQuery({
    queryKey: ['partners-infinite', JSON.stringify(filters)],
    queryFn: ({ pageParam = 1 }) => {
      return fetchPartners(Config.page_size_cards, pageParam, filters);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const params = new URLSearchParams(lastPage.data.next?.toString());
      if (params.get('page')) return Number(params.get('page'));
      return undefined;
    },
  });
}
