import { format } from 'date-fns';

import {
  AuditLogFilters,
  AuditLogListPaginationType,
} from '@/types/CommonTypes';
import {
  CreditBalanceListPaginationType,
  IPartnerFormFields,
  IUpdateBalanceFormFields,
  PartnerListPaginationType,
  PartnerSimpleType,
  PartnerType,
} from '@/types/PartnerTypes';
import { axiosFrontend } from '@/utils/axios';
import { Config } from '@/utils/Config';

export const fetchPartners = async (
  page_size = Config.page_size_cards,
  page = 1,
  filters = {},
) => {
  return axiosFrontend.get<PartnerListPaginationType>('/api/partners', {
    params: { page_size, page, ...filters },
  });
};

export const fetchPartnersSimple = async (filters = {}) => {
  return axiosFrontend.get<PartnerSimpleType[]>('/api/partners/simple', {
    params: { ...filters },
  });
};

export const fetchPartner = async (
  apiIdentifier: string | string[] | undefined,
) => {
  return axiosFrontend.get<PartnerType>(`/api/partners/${apiIdentifier}`);
};

export const createPartner = async (formData: Partial<IPartnerFormFields>) => {
  return axiosFrontend.post<PartnerType>('/api/partners', formData);
};

export const editPartner = async (
  apiIdentifier: string | string[] | undefined,
  formData: Partial<IPartnerFormFields>,
) => {
  return axiosFrontend.put<PartnerType>(
    `/api/partners/${apiIdentifier}`,
    formData,
  );
};

export const fetchPartnerAuditLogs = async (
  apiIdentifier: string | string[] | undefined,
  page_size: number | undefined,
  page: number | undefined,
  filters: AuditLogFilters,
) => {
  const copyFilters = {
    ...filters,
    created_at: `${format(
      filters.created_at[0].startDate,
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
    )},${format(filters.created_at[0].endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")}`,
  };
  return axiosFrontend.get<AuditLogListPaginationType>(
    `/api/partners/${apiIdentifier}/audit-logs`,
    {
      params: { page_size, page, ...copyFilters },
    },
  );
};

export const fetchPartnerCreditBalance = async (
  apiIdentifier: string | string[] | undefined,
  page_size = Config.page_size_cards,
  page = 1,
) => {
  return axiosFrontend.get<CreditBalanceListPaginationType>(
    `/api/partners/${apiIdentifier}/credit-balance`,
    {
      params: { page_size, page },
    },
  );
};

export const updateCreditBalance = async (
  apiIdentifier: string | string[] | undefined,
  formData: Partial<IUpdateBalanceFormFields>,
) => {
  return axiosFrontend.post(
    `/api/partners/${apiIdentifier}/credit-balance`,
    formData,
  );
};
